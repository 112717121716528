import React from "react"
import Type from "../../components/typography/Type"


const QuestionTypeTag = (props) => {	
	
const title = props.title; 	
const type = props.title;
		
	switch (title) {
		  
		  
		  case "Setup":
			return (
				<>
				<div className="h-10 rounded-full bg-grey/50 px-4 py-2">{title}</div>
				</>	
			  );
		  
		  
		  case "Consent":
		  return (
			  <>
			  <div className="h-10 rounded-full bg-grey/25 px-4 py-2">{title}</div>
			  </>	
			);
			
			case "Introduction":
			  return (
				  <>
				  <div className="h-10 rounded-full bg-yellow  px-4 py-2">{title}</div>
				  </>	
				);
			case "Probes":
		  	return (
			  	<>
			  	<div className="h-10 rounded-full bg-yellow/50  px-4 py-2">{title}</div>
			  	</>	
				);
			case "Closure":
			  return (
				  <>
				  <div className="h-10 rounded-full bg-grey/75 text-white px-4 py-2">{title}</div>
				  </>	
				);
	  default:
	  return (
	  	<>
		  <div className="h-10 rounded-full bg-green text-white px-4 py-2">{title}</div>
	  	</> 
	  );
	};	
};

export default QuestionTypeTag;
