import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import FeatureCard from "../../components/cards/FeatureCard"
import ProcessCard from "../../components/cards/ProcessCard"
import Collapsed from "../../components/controls/Collapsed"

import Toggle from "../../components/controls/Toggle"
import Layout from "../../components/layout/Layout"
import StatusAlert from "../../components/alerts/StatusAlert"
import QuestionTypeTag from "../../components/tags/QuestionTypeTag"


import { useState } from "react"



export default function Pages( {data}) {


return (
  <>
  <Layout>
  <Container>
  {isLoggedIn() ? (

 <div className="">
<div>
  <a href={data.airtable.data.Path}><Type type="p6">&lt; Back</Type></a>
  <Type type="p2">{data.airtable.data.Name}</Type>
  <Type type="p3" nopadding="yes">{data.airtable.data.ClientOrganisation}</Type>
  <Type type="p4">{data.airtable.data.ClientContactName}, {data.airtable.data.ClientEmail}</Type>
</div>
<div className="mt-16">

 {data.airtable.data.Protocols.map((ProtocolsArray) => (
   <>
    {ProtocolsArray.data.ProtocolMethods.map((MethodsArray) => (  
      <div className="mb-16 flex flex-col space-y-4">
        <div className="flex flex-row">
          <div>
           <Type type="h3" nopadding="yes">{MethodsArray.data.MethodTxt}</Type>
          </div>
          <div className="grow text-right">
            <Type type="h6" nopadding="yes">{MethodsArray.data.DurationTxt}</Type>
          </div>
        </div>
 
        {MethodsArray.data.DiscussionQuestions.map((QuestionsArray, QuestionIdx) => (  
            <div className="flex flex-row p-8 rounded-xl odd:bg-blue/0 even:bg-blue/20">
              <div className="w-1/12">{QuestionsArray.data.Name}</div>
              <div className="w-6/12">
                <Type type="p5" nopadding="yes">{QuestionsArray.data.Question}</Type></div>
              <div className="grow flex justify-end">
                <QuestionTypeTag title={QuestionsArray.data.Type}/>
              </div>
            </div>
        ))}
        </div>  
    ))}
    </>
 ))}


</div>

 </div>
 
    
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}
  </Container>
  </Layout>
    </>

  )
}

export const Head = () => <title>Sessions</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Projects" },
    data: { DiscussionPath: { eq: $Path } }) {
      data {
        Name
        Path
        ClientContactName
        ClientEmail
        ClientOrganisation
        Protocols { data {
          Name
          ProtocolMethods {data {
            Name
            MethodTxt
            DurationTxt
            OrderedMethodText
            DiscussionQuestions {data{
              Name
              Type
              Question
            }}
          }}
        }}
      }
    }
  }
`
